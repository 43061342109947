.ant-select-selector {
    border-radius: 5px !important;
}

.dark .ant-select-selector,
.dark .ant-select-selection-item {
    background-color: #0b1727 !important;
    border: none !important;
    color: #9da9bb !important;
}


.dark .ant-select-arrow {
    color: #9da9bb;
}

.dark .ant-select-dropdown {
    background-color: #232e3c ;
    font-family: 'Poppins' !important;
}

.dark .ant-select-item-option-content {
    color: #9da9bb !important;
}

.dark .ant-select-dropdown .ant-select-item-option-active, .dark .ant-select-item-option-selected {
    background-color: #121e2d !important;
}

.ant-select-item-option-content {
    font-family: 'Poppins' !important;
    font-size: 14px;
}